import Popper from "popper.js";
import SmoothScroll from "smooth-scroll";
import { SlowBuffer } from "buffer";


window.jQuery = $;
window.$ = $;

require("intersection-observer");
require("bootstrap");


function navMainHeight(){
	if (document.documentElement.clientWidth < 768){
		return 55
	}
	else return 70
}

var scroll = new SmoothScroll('a[href*="#"]', {
	ignore: '.desktop-start-link',
	offset: navMainHeight()
});



$(document).ready(function navbarSolidSetter() {
	if (document.documentElement.clientWidth < 768) {
		$(window).scroll(function() {
			if($(this).scrollTop() > 55) { 
					$('.navbar').removeClass("navbar-dark").addClass('solid navbar-light');
					
			} else {
					$('.navbar').removeClass('solid navbar-light').addClass('navbar-dark');
					
			}
		});
	} else {
		$('.navbar').removeClass("navbar-dark").addClass('solid navbar-light');
	}

});


document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    var active = false;
    
    const lazyLoad = function() {
        if (active === false) {
          active = true;
    
          setTimeout(function() {
            lazyImages.forEach(function(lazyImage) {
              if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 500 //threshold
              ) && getComputedStyle(lazyImage).display !== "none") {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.remove("lazy");
    
                lazyImages = lazyImages.filter(function(image) {
                  return image !== lazyImage;
                });
    
                if (lazyImages.length === 0) {
                  document.removeEventListener("scroll", lazyLoad);
                  window.removeEventListener("resize", lazyLoad);
                  window.removeEventListener("orientationchange", lazyLoad);
                }
              }
            });
    
            active = false;
          }, 200);
        }
      };
    
      document.addEventListener("scroll", lazyLoad);
      window.addEventListener("resize", lazyLoad);
      window.addEventListener("orientationchange", lazyLoad);
      window.addEventListener("popstate", lazyLoad);
      document.addEventListener("popstate", lazyLoad);
    
  });
